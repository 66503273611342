@tailwind base;
@tailwind components;
@tailwind utilities;
/* Add these lines in your CSS file */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

:root{
  --home-bg-color: #ffe0e0;
  --main-bg-color: #ff5b2e;
  --contact-bg-color: #fff2e2;
  --reviews-bg-color: #eee0f7;
}
body{
  overflow-x: hidden;
}
.quote{
  font-family: 'Roboto', sans-serif;
}

.spaciel-char {
  background: #723CCF;
  background: linear-gradient(to right, #723CCF 0%, #CF4E34 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
}

.hideIt{
  overflow: hidden;
}
.showIt{
  overflow: block;
}
body{
  overflow-x: hidden;
  background: #fff;
  font-family: 'Roboto', sans-serif;
}
.nav{
  background-color: var(--home-bg-color);
}
h1, h2, h3{
  font-family: 'Poppins', sans-serif;
}
.glassy{
  /* box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5); */
  backdrop-filter: blur(20px);
}
.hideit::-webkit-scrollbar{
  display: none;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  background-color: var(--contact-bg-color);
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 2px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #ea580c; 
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
}


.splide__pagination__page{
  background-color: green;
}
.splide__pagination__page.is-active{
  background-color: red;
}

.linear{
  background: rgb(185,57,0);
background: linear-gradient(99deg, rgba(185,57,0,0.8710407239819005) 48%, rgba(158,22,0,1) 100%);
}

/* Custom arrow styles */
.prev-arrow,
.next-arrow {
  font-size: 2rem; /* Adjust arrow size */
  color: white;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.prev-arrow:hover,
.next-arrow:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: yellow; /* Highlight color for hover */
}
